@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap");

:root {
  --primary-color: #353353;
  --secondary-color: #ff4c60;
}

body {
  margin: 0;
  font-family: "Rubik", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "Rubik", sans-serif;
}

div#root {
  max-width: 100vw;
  overflow-x: hidden;
}

.bounce-icon svg {
  animation: float 3s ease-in-out infinite;
  cursor: pointer;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.full-height, .full-height > div, .card-container > div {
  height: 100%;
}

#tsparticles {
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.App .MuiDrawer-paperAnchorDockedLeft {
  border: none;
}

.project-card img.MuiAvatar-img {
  object-fit: contain;
}

.p-form input:not([type="submit"]),
.p-form textarea {
  width: 100%;
  box-shadow: 0 5px 20px 0 rgb(69 67 96 / 10%);
  padding: 18px 30px;
  border-radius: 30px;
  border: none !important;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

.p-form input:focus-visible,
.p-form textarea:focus-visible {
  outline: none !important;
}

.p-form input[type="submit"] {
  background-color: var(--secondary-color);
  box-shadow: 0 5px 20px 0 rgb(0 0 0 / 20%);
  padding: 12px 55px;
  border: none;
  border-radius: 30px;
  color: #fff;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

.p-form fieldset {
  border: none !important;
}

.p-form .input-error {
  color: #bf1650;
  font-weight: 600;
}

.p-form .input-error::before {
  display: inline;
  content: "⚠ ";
}

.message {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 20px;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.message.success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.message.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.php-icon {
  right: 5px;
}

div.MuiGrid-container.card-row {
  margin-top: 1em;
}

/* Mobile and tablet */
@media screen and (max-width: 800px) {
  .card-container {
    padding: 15px 45px !important;
    margin-bottom: 40px !important;
  }
}